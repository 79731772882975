// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    columns: [],
    columnsOrder: [],
    comments: [],
    items: [],
    profiles: [],
    selectedItem: false,
    userStory: [],
    userStoryOrder: [],
    filteredAssignee: '',
    filteredRoom: '',
    archivedItems: [],
};

const slice = createSlice({
    name: 'kanban',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // ADD COLUMN
        addColumnSuccess(state, action) {
            state.columns = action.payload.columns;
            state.columnsOrder = action.payload.columnsOrder;
        },

        // EDIT COLUMN
        editColumnSuccess(state, action) {
            state.columns = action.payload.columns;
        },

        // UPDATE COLUMN ORDER
        updateColumnOrderSuccess(state, action) {
            state.columnsOrder = action.payload.columnsOrder;
        },

        // DELETE COLUMN
        deleteColumnSuccess(state, action) {
            state.columns = action.payload.columns;
            state.columnsOrder = action.payload.columnsOrder;
        },

        // ADD ITEM
        addItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // EDIT ITEM
        editItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // UPDATE COLUMN ITEM ORDER
        updateColumnItemOrderSuccess(state, action) {
            state.columns = action.payload.columns;
        },

        // SELECT ITEM
        selectItemSuccess(state, action) {
            state.selectedItem = action.payload.selectedItem;
        },

        // ADD ITEM COMMENT
        addItemCommentSuccess(state, action) {
            state.items = action.payload.items;
            state.comments = action.payload.comments;
        },

        // DELETE ITEM
        deleteItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // ADD STORY
        addStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // EDIT STORY
        editStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
        },

        // UPDATE STORY ORDER
        updateStoryOrderSuccess(state, action) {
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // UPDATE STORY ITEM ORDER
        updateStoryItemOrderSuccess(state, action) {
            state.userStory = action.payload.userStory;
        },

        // ADD STORY COMMENT
        addStoryCommentSuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.comments = action.payload.comments;
        },

        // DELETE STORY
        deleteStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // GET COLUMNS
        getColumnsSuccess(state, action) {
            state.columns = action.payload;
        },

        // GET COLUMNS ORDER
        getColumnsOrderSuccess(state, action) {
            state.columnsOrder = action.payload;
        },

        // GET COMMENTS
        getCommentsSuccess(state, action) {
            state.comments = action.payload;
        },

        // GET ROOMS 
        getRoomsSuccess(state, action) {
            state.rooms = action.payload;
        },

        // GET PROFILES
        getProfilesSuccess(state, action) {
            state.profiles = action.payload;
        },

        // GET ITEMS
        getItemsSuccess(state, action) {
            state.items = action.payload;
        },

        getArchivedItemsSuccess(state, action) {
            state.archivedItems = action.payload;
        },

        // GET USER STORY
        getUserStorySuccess(state, action) {
            state.userStory = action.payload;
        },

        // GET USER STORY ORDER
        getUserStoryOrderSuccess(state, action) {
            state.userStoryOrder = action.payload;
        },

        // GET ATTACHEMENTS
        getAttachementSuccess(state, action) {
            state.files = action.payload;
        },

        // DEFINE SELECTET ASSIGNEE
        setFilteredAssignee(state, action) {
            state.filteredAssignee = action.payload;
        },

        // DEFINE SELECTED ROOM
        setFilteredRoom(state, action) {
            state.filteredRoom = action.payload;
        },

        // DEFINE SELECTED STATUS
        setFilteredStatus(state, action) {
            state.filteredStatus = action.payload;
        },

        // Get fitlered room value
        getFilteredRoom(state, action) {
            return state.filteredRoom;
        },

        // Get fitlered assignee value
        getFilteredAssignee(state, action) {
            return state.filteredAssignee;
        },

        // get fitlered status value
        getFilteredStatus(state, action) {
            return state.filteredStatus;
        },

        setUpdateArchives(state, action) {
            state.updateArchives = new Date();
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getColumns() {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;

            const URLParams = new URLSearchParams(params);

            const response = await axios.get(`/api/kanban/columns${Object.keys(params).length > 0 ? '?' + URLParams.toString() : ''}`);
            dispatch(slice.actions.getColumnsSuccess(response.data.columns));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getColumnsOrder() {
    return async () => {
        try {
            // const response = await axios.get('/api/kanban/columns-order');
            dispatch(slice.actions.getColumnsOrderSuccess([
                "To Do",
                "Doing",
                "Done"
            ]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getComments() {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;

            const URLParams = new URLSearchParams(params);

            const response = await axios.get(`/api/kanban/comments${Object.keys(params).length > 0 ? '?' + URLParams.toString() : ''}`);
            dispatch(slice.actions.getCommentsSuccess(response.data.comments));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function uploadAttachements(files, itemId) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/item-attachements', {upload: files, itemId}, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            dispatch(slice.actions.getAttachementSuccess(response.data.files));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAttachements(itemId) {
    return async () => {
        try {
            const response = await axios.get('/api/kanban/item-attachements/' + itemId);
            dispatch(slice.actions.getAttachementSuccess(response.data.files));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeAttachement(fileId) {
    return async () => {
        try {
            const response = await axios.delete('/api/kanban/item-attachements/' + fileId);
            dispatch(slice.actions.getAttachementSuccess(response.data.files));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRooms() {
    return async () => {
        try {
            const response = await axios.get('/api/rooms');
            dispatch(slice.actions.getRoomsSuccess(response.data.rooms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProfiles() {
    return async () => {
        try {
            const response = await axios.get('/api/kanban/profiles');
            dispatch(slice.actions.getProfilesSuccess(response.data.profiles));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getItems() {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;
            if(getState().kanban.filteredStatus && getState().kanban.filteredStatus !== '') params.status = getState().kanban.filteredStatus;

            const URLParams = new URLSearchParams(params);

            const response = await axios.get(`/api/kanban/items${Object.keys(params).length > 0 ? '?' + URLParams.toString() : ''}`);
            dispatch(slice.actions.getItemsSuccess(response.data.items));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getArchivedItems(from = 0, offset = 50) {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;
            params.from = from;
            params.offset = offset;
            params.status = 'Archived';

            const response = await axios.get('/api/kanban/items?' + new URLSearchParams(params).toString());
            dispatch(slice.actions.getArchivedItemsSuccess([...getState().kanban.archivedItems, ...response.data.items]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetArchivedItems() {
    return async () => {
        dispatch(slice.actions.getArchivedItemsSuccess([]));
    };
}

export function getUserStory() {
    return async () => {
        try {
            const response = await axios.get('/api/kanban/userstory');
            dispatch(slice.actions.getUserStorySuccess(response.data.userStory));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserStoryOrder() {
    return async () => {
        try {
            const response = await axios.get('/api/kanban/userstory-order');
            dispatch(slice.actions.getUserStoryOrderSuccess(response.data.userStoryOrder));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addColumn(column, columns, columnsOrder) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-column', { column, columns, columnsOrder });
            dispatch(slice.actions.addColumnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editColumn(column, columns) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/edit-column', { column, columns });
            dispatch(slice.actions.editColumnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnOrder(columnsOrder) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/update-column-order', { columnsOrder });
            dispatch(slice.actions.updateColumnOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteColumn(columnId, columnsOrder, columns) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/delete-column', { columnId, columnsOrder, columns });
            dispatch(slice.actions.deleteColumnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addItem(columnId, columns, item, items, storyId, userStory) {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;

            const URLParams = new URLSearchParams(params);

            const response = await axios.post(`/api/kanban/add-item${Object.keys(params).length > 0 ? '?' + URLParams.toString() : ''}`, { columnId, columns, item, items, storyId, userStory });
            dispatch(slice.actions.addItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editItem(columnId, columns, item, items, storyId, userStory) {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;

            const URLParams = new URLSearchParams(params);

            const response = await axios.post(`/api/kanban/edit-item${Object.keys(params).length > 0 ? '?' + URLParams.toString() : ''}`, { items, item, userStory, storyId, columns, columnId });
            dispatch(slice.actions.editItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnItemOrder(columns) {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;

            const URLParams = new URLSearchParams(params);

            const response = await axios.post(`/api/kanban/update-item-order${Object.keys(params).length > 0 ? '?' + URLParams.toString() : ''}`, { columns });
            dispatch(slice.actions.updateColumnItemOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function selectItem(selectedItem) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/select-item', { selectedItem });
            dispatch(slice.actions.selectItemSuccess({selectedItem}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addItemComment(itemId, comment, items, comments) {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;

            const URLParams = new URLSearchParams(params);

            const response = await axios.post(`/api/kanban/add-item-comment${Object.keys(params).length > 0 ? '?' + URLParams.toString() : ''}`, { items, itemId, comment, comments });
            dispatch(slice.actions.addItemCommentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteItem(itemId, items, columns, userStory) {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if(getState().kanban.filteredAssignee && getState().kanban.filteredAssignee !== '') params.assignee = getState().kanban.filteredAssignee;
            if(getState().kanban.filteredRoom && getState().kanban.filteredRoom !== '') params.room = getState().kanban.filteredRoom;

            const URLParams = new URLSearchParams(params);

            const response = await axios.post(`/api/kanban/delete-item${Object.keys(params).length > 0 ? '?' + URLParams.toString() : ''}`, { columns, itemId, userStory, items });
            dispatch(slice.actions.deleteItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addStory(story, userStory, userStoryOrder) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-story', { userStory, story, userStoryOrder });
            dispatch(slice.actions.addStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editStory(story, userStory) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/edit-story', { userStory, story });
            dispatch(slice.actions.editStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateStoryOrder(userStoryOrder) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/update-story-order', { userStoryOrder });
            dispatch(slice.actions.updateStoryOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateStoryItemOrder(userStory) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/update-storyitem-order', { userStory });
            dispatch(slice.actions.updateStoryItemOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addStoryComment(storyId, comment, comments, userStory) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-story-comment', { userStory, storyId, comment, comments });
            dispatch(slice.actions.addStoryCommentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteStory(storyId, userStory, userStoryOrder) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/delete-story', { userStory, storyId, userStoryOrder });
            dispatch(slice.actions.deleteStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setFilteredAssignee(assignee) {
    return async () => {
        try {
            dispatch(slice.actions.setFilteredAssignee(assignee));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setFilteredRoom(room) {
    return async () => {
        try {
            dispatch(slice.actions.setFilteredRoom(room));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setFilteredStatus(status) {
    return async () => {
        try {
            dispatch(slice.actions.setFilteredStatus(status));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function bulkAction(action, items) {
    return async () => {
        try {
            console.log("Bulk action");
            await axios.post('/api/kanban/bulk', { action, items });
            // dispatch(slice.actions.bulkActionSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function bulkAssign(assignee, items) {
    return async () => {
        try {
            await axios.post('/api/kanban/bulk-assign', { assignee, items });
            // dispatch(slice.actions.bulkAssignSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateArchives() {
    return async () => {
        dispatch(slice.actions.setUpdateArchives());
    };
}